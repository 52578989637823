<template>
    <main>
      <div
        class="modal fade"
        id="modalAreaLaboratorio"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Registro de Areas de Laboratorio
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="card-waves ">
                <div class="card-header bg-light text-dark"><i class="fa fa-book fa-fw text-primary"></i>Datos</div>
                <div class="card-body">
                  <div class="row">
                    <div class="form-group col-lg-12 col-md-12 col-sm-12">
                      <label class="form-label">Nombre</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="areas.nombre"
                        :class="{
                          'is-invalid':
                            $v.areas.nombre.$error && $v.areas.nombre.$dirty,
                        }"
                      />
                    </div>

                    <div class="form-group col-lg-12 col-md-12 col-sm-12">
                        <label class="form-label">Descripcion</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="areas.descripcion"
                          :class="{
                            'is-invalid':
                              $v.areas.descripcion.$error && $v.areas.descripcion.$dirty,
                          }"
                        />
                    </div>

                  
                    
                    
                  </div>
                </div>
              </div>
              
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary btn-sm"
                @click="save()"
              >
                Guardar<i class="fa fa-save fa-fw"></i>
              </button>
              <button
                type="button"
                class="btn btn-light btn-sm"
                @click="cerrar()"
              >
                Cerrar<i class="fa fa-times-circle fa-fw"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  </template>
  <script>
  

  import AreaLaboratorioService from '../../../../../services/AreaLaboratorioService';
  
  import Toast from "../../../../../components/common/utilities/toast";
  import { required } from "vuelidate/lib/validators";
  import $ from "jquery";
  
  export default {
    data() {
      return {
        areas: {
          id: "",
          nombre: "",
          descripcion: ""
        },
        
        type: "store",
      };
    },
    validations() {
      return {
        areas: {
          nombre: {required},
          descripcion: {required}
        },
        
      };
    },
    methods: {
      
      async save() {
        try {
          this.$v.areas.$touch();
           
          if (this.$v.areas.$invalid) {
            return;
          }

          this.LoaderSpinnerShow();

          if (this.type === "store") {
            await AreaLaboratorioService.store(this.areas);
            this.type = "store";
            this.limpiarCampos();
          } else if (this.type === "edit") {
            await AreaLaboratorioService.update(this.areas);
            this.limpiarCampos();
            this.type = "store";
          }
  
          this.LoaderSpinnerHide();
  
          Toast.fire({
            icon: "success",
            title: "Datos guardados con exito",
          });

          this.$emit("cargar-areas");
  
          $("#modalAreaLaboratorio").modal("hide");
          
        } catch (err) {
          console.error(err);
          this.LoaderSpinnerHide();
          Toast.fire({
            icon: "error",
            title: "Ocurrio un error al procesar la solicitud",
          });
        }
      },
      async limpiarCampos() {
      this.areas.nombre = "";
      this.areas.descripcion = "";

 
    },
      
      cerrar() {
        this.limpiarCampos();
        $("#modalAreaLaboratorio").modal("hide");
      },
      async edit(id) {
        this.$nextTick(async () => {
          this.type = "edit";
          $("#modalAreaLaboratorio").modal("show");
          const response = await AreaLaboratorioService.show(id);
          this.areas.id = response.data.id;
          this.areas.nombre = response.data.nombre;
          this.areas.descripcion = response.data.descripcion;
          
        });
      },
    },
  };
  </script>
  